<template>
  <div class="searchList">
    <!-- <div class="search_box">
            <el-form ref="form" :model="formInline">
              <el-row>
                <el-col :span="10">
                  <el-form-item label="订单编号" style="display: flex">
                    <el-input></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="创建日期" style="display: flex">
                    <el-input></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" style="text-align: right">
                  <el-button type="info" plain>重置</el-button>
                  <el-button type="success" @click="search">搜索</el-button>
                </el-col>
              </el-row>
              
            </el-form>
    </div> -->

    <div class="searchContent-head">
        <div class="search_title">
            <el-tabs @tab-click="tabHandleClick">
                <el-tab-pane label="全部" name=""></el-tab-pane>
                <el-tab-pane label="待开票" name="1"></el-tab-pane>
                <el-tab-pane label="开票中" name="2"></el-tab-pane>
                <el-tab-pane label="已开票" name="3"></el-tab-pane>
            </el-tabs>
            <span style="line-height: 40px; margin-left: auto; color: #999999">请在小程序端进行相关操作</span>
        </div>
        <el-table
            :data="tableData"
            style="width: 100%;"
            class=""
            >
            <el-table-column
            prop="orderNo"
            label="订单编号"
            sortable
            width="270">
            </el-table-column>
            <el-table-column
            prop="totalPurchaseFee"
            label="发票金额"
            sortable
            width="150">
                <template slot-scope="scope">
                    <span>{{scope.row.totalPurchaseFee/10000}}</span>
                </template>
            </el-table-column>
            <el-table-column
            prop="orderState"
            label="订单状态"
            sortable>
                <template slot-scope="scope">
                    <span v-if="scope.row.orderState == 1">待付款</span>
                    <span v-if="scope.row.orderState == 2">待发货</span>
                    <span v-if="scope.row.orderState == 3">待收货</span>
                    <span v-if="scope.row.orderState == 4">待评价</span>
                    <span v-if="scope.row.orderState == 5">已完成</span>
                    <span v-if="scope.row.orderState == 6">已关闭</span>
                    <span v-if="scope.row.orderState == 7">售后中</span>
                </template>
            </el-table-column>
            <el-table-column
            prop="area"
            label="开票状态"
            sortable>
                <template slot-scope="scope">
                    <span v-if="scope.row.isInvoice == 0" style="color:#E32319;">待开票</span>
                    <span v-if="scope.row.isInvoice == 1" style="color:#366713;">开票中</span>
                    <span v-if="scope.row.isInvoice == 2" style="color:#366713;">已完成</span>
                </template>
            </el-table-column>
            <el-table-column
            prop="address"
            label="操作"
            sortable>
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="table_title">
              <span style="width: 50%">订单编号</span>
              <span style="width: 12%">发票金额</span>
              <span style="width: 12%">订单状态</span>
              <span style="width: 14%">开票状态</span>
              <span style="width: 12%">操作</span>
          </div>

          <div class="table_content" v-for="(item,index) in tableData" :key="index">
              <div class="content_title">
                  <span style="margin-right: 50px">订单编号：{{item.orderNo}}</span>
                  <span>创建时间：{{item.createTime}}</span>
                  <a @click="goOrderDetail(item)">订单详情</a>
              </div>
              <div class="content_centent" v-for="(it,inx) in item.uocOrderGoodsRspBOList" :key="inx">
                    <div style="width: 50%; padding: 0 30px;">
                        <div style="display: flex;">  
                            <img class="goodslogo" :src="it.spuPicUrl" alt="">
                            <div class="goodsdetail">
                                <p>{{it.spuName}}</p>
                                <span>规格：{{it.attrNames}}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div style="width: 12%; text-align: center">
                        <p>￥{{it.price / 10000}}</p>
                    </div>

                    <div style="width: 12%; text-align: center">
                        <p>{{it.productAmount}}</p>
                    </div>
                    <div style="width: 12%; text-align: center">
                        <p>￥{{item.totalPurchaseFee / 10000}}</p>
                    </div>

                    <div style="width: 14%; text-align: center" v-if="inx == 0">
                        <p>{{orderstatus(item.orderState)}}</p>
                    </div>
                    <div style="width: 14%; text-align: center" v-else>

                    </div>
              </div>
              
          </div> -->

          <!-- <div style="text-align: right; margin-top: 30px;">
               <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
          </div> -->
        
    </div>
  </div>
    
</template>

<script>
export default {
    data(){
        return {
            formInline: {
                isInvoice: '',
                pageNo: 1,
                pageSize: 10
            },
            pageInfo: {
                total: 0
            },
            tableData: []
        }
    },
    mounted(){
        this.search();
    },
    methods: {
        handleClick(e) {
            this.$router.push({
                path: 'billDetail',
                query: {
                    orderNo: e.orderNo
                }
            })
        },
        search(){
            this.qa.queryUocOrderListForBuyers(this.formInline).then(res => {
                this.tableData = res.data.rows;
                this.pageInfo.total = res.data.recordsTotal;
            })
        },
        tabHandleClick(e) {
            // * 订单状态 0创建 1 待付款 2待发货 3待收货 4待评价5已完成6已关闭/已取消7售后中
            if( e.index == '0' ) {
                this.formInline.isInvoice =  ''
            }else {
                this.formInline.isInvoice = e.index - 1
            }
            
            this.qa.queryUocOrderListForBuyers(this.formInline).then(res => {
                this.tableData = res.data.rows;
                this.pageInfo.total = res.data.recordsTotal;
            })
        },
        orderstatus(status){
            if(status == 0) {
                return '创建'
            } else if(status == 1) {
                return '待付款'
            } else if(status == 2) {
                return '待发货'
            } else if(status == 3) {
                return '待收货'
            } else if(status == 4) {
                return '待评价'
            } else if(status == 5) {
                return '已完成'
            } else if(status == 6) {
                return '已关闭/已取消'
            } else if(status == 7) {
                return '售后中'
            }
        },
        goOrderDetail(row){
            this.$router.push({
                name: 'orderDetail',
                query: {
                    id: row.id
                }
            })
        }
    }
}
</script>

<style scoped>
.search_box {
    background: #fff;
    padding: 20px 20px 0;
    border-radius: 8px;
  }
  .searchList>>>.el-tabs__nav-wrap::after {
    height: 0;
}
.searchList>>>.is-active {
    color: #84C754;
}
.searchList>>>.el-tabs__active-bar {
    background: #84C754;
}
.searchContent-head {
    border-radius: 8px;
    background: #fff;
    padding: 0 15px;
}
.search_title {
    display: flex;
    padding: 20px 15px 10px 10px;
    margin-top: 20px;
}
.table_title {
    line-height: 40px;
    color: #333;
    width: 100%;
    height: 40px;
    background: #EEEEEE;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
}
.table_title span {
    display: inline-block;
    vertical-align: top;
}
.table_content {
    width: 100%;
    margin: 20px 0;
    background: #fff;
    border: 1px solid #EFFAE8;
}
.content_title {
    width: 100%;
    height: 40px;
    background: #EFFAE8;
    line-height: 40px;
    font-size: 13px;
    text-align: left;
    padding: 0 30px;
}

.content_title >span {
    font-weight: 500;
    color: #333333;
}
.content_title >a {
    float: right;
    font-weight: 400;
    color: #378400;
}
.content_centent {
    width: 100%;
    padding: 22px 0;
}

.content_centent >div {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
}

.goodslogo {
    width: 90px; 
    height: 90px;
    display: inline-block;
    margin-right: 20px;
}

.goodsdetail {
    display: inline-block;
    text-align: left;
}
.goodsdetail p {
    margin-bottom: 10px;
}
.goodsdetail span {
    color: #999;
    
}
.el-button--text {
    color: #366713 !important;
}
</style>